import { createApp } from 'vue'
import App from './App.vue';
import router from './router/index';
import store from './store/index'
import Toaster from '@meforma/vue-toaster';
import axios from 'axios';
import './http/axios.interceptor';
import './assets/css/main.scss';
import VueSocialSharing from 'vue-social-sharing'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '@/firebase/config';
import LogRocket from 'logrocket';

LogRocket.init('ifwagc/webapp');
const app = createApp(App);

app.config.globalProperties.$http = axios;
axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;

onAuthStateChanged(auth, (user) => {
  store.commit('setAuthIsReady', true);
  store.commit('setUser', user);

  app
    .use(store)
    .use(router)
    .use(Toaster)
    .use(VueSocialSharing)
    .mount('#app');
});
