<template>
  <main v-if="visible" v-bind:key="itemId" class="site-content form mint-tag">

    <!-- Esatdo del tag -->
    <TagStatus
      :tagId="tagId">
    </TagStatus>

    <!-- se manda id y existe el tag (datos del tag) -->
    <div v-if="tag && tagId" class="wrapper">
      <div class="form-body">
        <div class="form-post-preview">
          <span v-for="(dates, date) in tag.attachesWithHistory" v-bind:key="dates">
            <div class="post-date">{{ date }}</div>
            <span v-for="history in dates" v-bind:key="history.id">
              <div class="post-content">
                <p v-if="isTextAttachment(history)">{{ history.contentText }}</p>
                <p v-if="isTitAttachment(history)">{{ history.contentText }}</p>
                <img v-if="isImageAttachment(history)" :src="history.contentUrl" alt="Post image" style="padding-bottom: 10px;">
              </div>
            </span>
          </span>
        </div>
      </div>

      <!-- se manda id y existe el tag y es del usuario logado (formulario del tag) -->
      <div class="wrapper" v-if="showForm">
        <form @submit.prevent="pressed">
          <div class="form-body">
            <p v-if="v$.textInput.$model || 0 === this.v$.imageInput.$model">
              <textarea v-model="v$.textInput.$model" name="text" placeholder="write..."></textarea>
            </p>
            <p v-if="imagePreview" >
              <img :src="imagePreview" alt="Preview image for this history"/>
            </p>
            <p class="form-submit">
              <input @change="imageChange" id="fileUpload" name="image" type="file" hidden>
              <button v-if="!v$.imageInput.$model && !v$.textInput.$model" @click="chooseImage()" class="button orange icon-camera"></button>
              <button v-if="v$.textInput.$model" v-on:click.prevent="reset()" class="button mint-cancel">Cancel</button>
              <button v-if="v$.imageInput.$model" v-on:click.prevent="reset()" class="button mint-cancel">Cancel</button>
              <input :disabled="disableSubmit" :class="disableSubmitClass" type="submit" class="button orange mint-submit" value="Post">
            </p>
          </div>
        </form>
        <AppFormError
          :error="error">
        </AppFormError>
      </div>

      <!-- se manda id y existe el tag (social media) -->
      <aside class="social-sharing fixed" id="social-sharing-id">
        <p>Share</p>
        <div class="wrapper">
          <ul>
            <li>
              <ShareNetwork
                class=""
                network="twitter"
                :url="currentUrl"
                :title="tag.title"
                :description="tag.subtitle">
                <img :src="require(`@/assets/img/icon-twitter.svg`)" alt="Twitter Icon">
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
                class=""
                network="facebook"
                :url="currentUrl"
                :title="tag.title"
                :description="tag.subtitle">
                <img :src="require(`@/assets/img/icon-facebook.svg`)" alt="Facebook Icon">
              </ShareNetwork>
            </li>
            <li>
              <a v-on:click.prevent="copyUrl" style="cursor: pointer">
                <img :src="require(`@/assets/img/icon-link.svg`)" alt="Icon copy URL">
                <input type="hidden" id="current-url-id" :value="currentUrl">
              </a>
            </li>
          </ul>
        </div>
      </aside>
    </div>

    <!-- se manda id pero no existe el tag -->
    <div v-if="null === tag && tagId" class="user-tags tag-error">
      <div class="user-tags-header">
        <div class="wrapper">
          <div class="error-message">
            <strong>Can't find this tag</strong>
            If you need help, please visit uniiku telegram group.
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { ShareNetwork } from 'vue-social-sharing/src/vue-social-sharing';
import { UniikuService } from '@/services/uniiku.service';

import AppFormError from "@/components/app/AppFormError.vue";
import TagStatus from "@/components/status/TagStatus.vue";

import { required } from '@vuelidate/validators';
import useVuelidate from "@vuelidate/core";
import { reactive } from 'vue';
import $ from 'jquery';

export default {
  name: 'TagShow',
  props: {
    tagId: {
      default: null
    }
  },
  components: {
    TagStatus,
    ShareNetwork,
    AppFormError
  },
  watch: {
    async tagId(tagId) {
      this.itemId = tagId;
      await this.loadTag();
    },
    '$store.state.user': function() {
      this.user = this.$store.getters['user'];
    }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  data () {
    return {
      itemId: null,
      tag: false,
      currentUrl: window.location.origin + this.$route.fullPath,
      user: this.$store.getters['user'],
      visible: false,

      // Form
      textInput: null,
      imageInput: false,
      imageFile: null,
      imagePreview: null,
      processSubmit: false,
      error: null,
    }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  setup () {
    const state = reactive({
      textInput: null,
      imageInput: 0
    })
    const rules = {
      textInput: { required },
      imageInput: { required },
    }
    const v$ = useVuelidate(rules, state);
    return { state, v$ }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  async mounted() {
    await this.loadTag();
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  methods: {

    async loadTag() {

      this.initForm();
      this.service = new UniikuService();

       // Comprobar tag
      if (this.tagId) {
        this.tag = await this.service.getTagDetail(this.tagId);
      }

      if (this.tag) {
        let social_sharing = $('#social-sharing-id');
        let site_footer_height = $('#site-footer').height();

        social_sharing.addClass('fixed');

        $(window).on('scroll', function () {
          let scrollHeight = $(document).height();
          let scrollPosition = $(window).height() + $(window).scrollTop();

          if ((scrollHeight - scrollPosition) < site_footer_height) {
            if (social_sharing.hasClass('fixed')) {
              social_sharing.removeClass('fixed');
            }
          } else {
            if (!social_sharing.hasClass('fixed')) {
              social_sharing.addClass('fixed');
            }
          }
        })
      }

      this.visible = true;
    },

    // Inicializar el form
    initForm: function () {
      this.textInput = null;
      this.imageInput = false;
      this.imageFile = null;
      this.imagePreview = null;
      this.processSubmit = false;
      this.error = null;

      this.v$.textInput.$model = null;
      this.v$.imageInput.$model = 0;
    },

    // Mostrar tag
    isImageAttachment: function(attachment) {
      return 'IMG' === attachment.contentType;
    },

    isTextAttachment: function(attachment) {
      return 'TXT' === attachment.contentType;
    },

    isTitAttachment: function(attachment) {
      return 'TIT' === attachment.contentType;
    },

    // Social media
    copyUrl () {
      let copyUrl = document.querySelector('#current-url-id');
      copyUrl.setAttribute('type', 'text');
      copyUrl.select();
      document.execCommand('copy')
      copyUrl.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
      this.$toast.show(`URL copied to clipboard!`);
    },

    // Formulario frl tag
    chooseImage() {
      document.getElementById('fileUpload').click();
    },

    imageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.v$.imageInput.$model = files.length;

      this.imageFile = e.target.files[0];
      this.imagePreview = URL.createObjectURL(this.imageFile);
    },

    async pressed() {
      try {
        this.processSubmit = true;

        // Crear texto
        if (this.v$.textInput.$model) {
          const history = await this.service.createHistory(this.tag.id);
          await this.service.createAttachHistoryTit(this.tag.id, history.id, this.v$.textInput.$model);
          await this.service.createAttachHistorySign(this.tag.id, history.id);
          this.tag = await this.service.getTagDetail(this.tagId);
        }

        // Crear imagen
        if (0 < this.v$.imageInput.$model) {
          const history = await this.service.createHistory(this.tag.id);
          await this.service.createAttachHistoryImg(this.tag.id, history.id, this.imageFile);
          await this.service.createAttachHistorySign(this.tag.id, history.id);
          this.tag = await this.service.getTagDetail(this.tagId);
        }

        // Guardar cambios
        await this.$emit('update-tag', this.tag.id);
        this.processSubmit = false;

        this.reset();
      } catch (error) {
        this.error = error.message;
      }
    },

    reset() {
      this.v$.imageInput.$model = 0;
      this.v$.textInput.$model = null;
      this.imagePreview = null;
      this.error = null;
    }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  computed: {

    showForm() {
      if (null === this.user) {
        return false;
      }
      return this.tag.owner.id === this.user.uuid;
    },

    disableSubmit() {
      if (this.processSubmit) {
        return true;
      }
      return !(this.v$.textInput.$model || 0 < this.v$.imageInput.$model);
    },

    disableSubmitClass() {
      if (this.processSubmit) {
        return 'disabled';
      }
      if (this.v$.textInput.$model || 0 < this.v$.imageInput.$model) {
        return '';
      }
      return 'disabled';
    }
  }
}
</script>

<style scoped>
</style>
