<template>
  <span v-if="visible">
    <TagStatus
      :tagId="tagId">
    </TagStatus>

    <main class="site-content form register-form">
      <div class="wrapper">
        <h1 class="icon-user">Forgot password?</h1>
        <form @submit.prevent="pressed">
          <div class="form-body">
            <p><input v-model="v$.email.$model" type="text" name="email" placeholder="Your email in uniiku" /></p>
            <p class="form-submit">
              <button :disabled="disabled" :class="disabledClass" type="submit" class="button orange" value="Login">Reset password</button>
            </p>
            <p class="alternative-actions login">
              <router-link :to="{ name: 'route_login', params: { tagId: this.tagId }}">Already have an account</router-link>
            </p>
          </div>
        </form>
        <AppFormError
          :error="error">
        </AppFormError>
      </div>
    </main>
  </span>
</template>

<script>
import { UniikuService } from '@/services/uniiku.service';

import AppFormError from '@/components/app/AppFormError.vue';
import TagStatus from "@/components/status/TagStatus.vue";

import { required, email } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { reactive } from 'vue';
import { logger } from '@/services/logger';

export default {
  name: 'ResetPassword',
  components: {
    TagStatus,
    AppFormError,
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  data() {
    return {
      tag: null,
      tagId: null,
      email: '',
      error: '',
      visible: false,
    };
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  setup () {
    const state = reactive({ email: '' })
    const rules = {
      email: { required, email }
    }
    const v$ = useVuelidate(rules, state);
    return { state, v$ }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  async mounted() {
    this.tagId = this.$route.params.tagId;
    this.visible = true;
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  computed: {
    disabled() {
      return !this.v$.email.$model;
    },

    disabledClass() {
      if (this.v$.email.$model) {
        return '';
      }
      return 'disabled';
    }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  methods: {
    async pressed() {
      try {
        if (true === await this.v$.$validate()) {
          await this.$store.dispatch('resetPassword', {
            email: this.v$.email.$model
          });
          await this.$router.push({ name: 'route_login', params: { tagId: this.tagId }});
        } else {
          this.error = 'Invalid email';
        }
      } catch (error) {
        logger.error(error);
        this.error = error.message;
      }
    }
  }
}
</script>

<style scoped>
</style>
