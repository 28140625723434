<template>
  <span v-if="visible">
    <main  class="site-content form register-form">
      <div class="wrapper">
        <h1 class="icon-user">Edit My profile</h1>
        <form @submit.prevent="pressed">
          <div class="form-body">
            <p><input type="file" placeholder="Change pic" @change="handleFileUpload" /></p>
            <p><img v-if="imagePreviewUrl" :src="imagePreviewUrl" alt="Image Preview" class="user-profile-image"></p>
            <p><input v-model="data.name" type="text" placeholder="Your name" required /></p>
            <p><input v-model="data.description" type="text" placeholder="Something about you..." /></p>
            <p><input v-model="data.location" type="text" placeholder="Location" /></p>
            <p><input v-model="data.links.instagram" type="text" placeholder="Instagram" /></p>
            <p><input v-model="data.links.linkedin" type="text" placeholder="Linkedin" /></p>
            <p><input v-model="data.links.twitter" type="text" placeholder="Twitter" /></p>
            <p class="form-submit">
              <button type="submit" class="button orange" value="Login">Save my profile</button>
            </p>
          </div>
        </form>
        <AppFormError
          :error="error">
        </AppFormError>
      </div>
    </main>
  </span>
</template>

<script>
import AppFormError from "@/components/app/AppFormError.vue";

import { UniikuService } from '@/services/uniiku.service';
import { get } from 'lodash';

export default {
  name: 'EditProfile',
  components: {
    AppFormError
  },
  watch: {
    '$store.state.user': function() {
      this.user = this.$store.getters['user'];
    }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  data() {
    return {
      uid: null,
      uuid: null,
      user: null,
      visible: false,
      error: null,
      imagePreviewUrl: null,
      data: {
        name: null,
        description: null,
        location: null,
        imageFile: null,
        links: {
          instagram: null,
          linkedin: null,
          twitter: null,
        }
      },
    }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  async mounted() {
    this.service = new UniikuService();
    this.user = await this.service.getUser();
    this.showForm();
    this.visible = true;
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  methods: {

    async pressed() {
      try {
        await this.service.updateUser(this.uuid, this.data);
        await this.$store.commit('setUser', await this.service.getUser());
        this.showProfile();
      } catch (error) {
        this.error = error.message;
      }
    },

    showForm() {
      this.imagePreviewUrl = get(this.user, 'imageUrl', null);
      this.data.name = get(this.user, 'name', null);
      this.data.description = get(this.user, 'description', null);
      this.data.location = get(this.user, 'location', null);
      this.data.links.instagram = get(this.user, 'links.instagram', null);
      this.data.links.linkedin = get(this.user, 'links.linkedin', null);
      this.data.links.twitter = get(this.user, 'links.twitter', null);
    },

    async showProfile() {
      await this.$router.push({ name: 'route_profile', params:{userId: this.user.uuid} });
    },

    handleFileUpload(event) {
      // Verifica si hay archivos seleccionados
      if (event.target.files && event.target.files.length) {

        // Obtiene el primer archivo seleccionado
        const file = event.target.files[0];

        // Opcional: Validar el archivo (tipo, tamaño, etc.)

        // Almacena el archivo en la propiedad del componente
        this.data.imageFile = file;

        // Opcional: Mostrar una vista previa de la imagen
        this.previewImage(file);
      }
    },

    // Método opcional para mostrar una vista previa de la imagen
    previewImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // Esta URL se puede usar para mostrar una vista previa de la imagen
        this.imagePreviewUrl = e.target.result;
      };

      // Lee el archivo como una URL de datos
      reader.readAsDataURL(file);
    },
  },
}
</script>

<style scoped>
  .user-profile-image {
    /* Asegura que la imagen sea un círculo */
    border-radius: 50%;

    /* Ajusta el tamaño de la imagen a tus necesidades */
    width: 100px; /* o el tamaño que prefieras */
    height: 100px; /* debe ser igual al ancho para mantener la proporción de círculo */

    /* Opcional: Añade un borde si lo deseas */
    border: 2px solid #F08F00; /* color de borde negro, ajusta como prefieras */

    /* Opcional: Añade algo de sombra para un efecto elevado */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    /* Asegúrate de que la imagen cubra el contenedor sin deformarse */
    object-fit: cover;
  }
</style>
