<template>
  <span v-if="visible">
    <TagShow
      :tagId="tagId">
    </TagShow>
  </span>
</template>

<script>
import { UniikuService } from '@/services/uniiku.service';

import TagShow from '@/components/tag/TagShow.vue';

export default {
  name: 'Home',
  components: {
    TagShow,
  },
  watch: {
    '$store.state.user': function() {
      this.user = this.$store.getters['user'];
    }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  data () {
    return {
      tag: null,
      tags: null,
      tagId: this.$route.params.tagId,
      user: this.$store.getters['user'],
      visible: false,
    }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  async mounted() {
    this.service = new UniikuService();

    // Obtener tag por id
    if (this.tagId) {
      this.tag = await this.service.getTag(this.tagId);
      this.visible = true;
      return;
    }

    // Obtener tags del usario(logado)
    if (this.user) {
      await this.$router.push({ name: 'route_profile'});
      return;
    }

    await this.$router.push({ name: 'route_register', params: { tagId: this.tagId }});
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  methods: {
    async updateTag(tagId) {
      this.tagId = tagId;
    }
  }
}
</script>

<style scoped>
</style>
