<template>
    <div class="user-tags listing">
    <div class="user-tags tag-error">
      <div class="user-tags-header">
        <div class="wrapper">
          <div class="error-message">
            <strong>Page not found</strong>
            If you need help, please visit uniiku telegram group.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
</script>
<style scoped>
</style>