import { get, groupBy } from 'lodash';
import moment from "moment";
import axios from 'axios';
import { logger } from './logger';

export class UniikuService {

  /* -------------------------------------------------------------------------
   *  TAG - FORMAT
   * ---------------------------------------------------------------------- */

  detail(tag) {
    const attaches = get(tag, 'attachesWithHistory', null);

    if (attaches) {
      tag.attaches = attaches;
      tag.attachesWithHistory = groupBy(attaches, 'dateSigned');
    }

    if (get(tag, 'dateMinted', null)) {
      tag.dateMintedFormat = moment(tag.dateMinted).format('Do of MMMM, YYYY');
    }

    if (get(tag, 'dateCreated', null)) {
      tag.dateCreatedFormat = moment(tag.dateCreated).format('Do of MMMM, YYYY');
    }

    return tag;
  }

  /* -------------------------------------------------------------------------
   *  TAG
   * ---------------------------------------------------------------------- */

  async getTags() {
    const { data } = await axios.get(`/tags`);
    return data;
  }

  async getTag(id) {
    try {
      const { data } = await axios.get(`/tags/${id}`)
      return data;
    } catch (error) {
      return null;
    }
  }

  async getTagDetail(id) {
    try {
      const { data } = await axios.get(`/tags/${id}`)
      return this.detail(data);
    } catch (error) {
      return null;
    }
  }

  async getUserTags(userId) {
    const { data } = await axios.get(`/users/${userId}/tags`)
    return data;
  }

  async isOwned(uid) {
    try {
      const { data } = await axios.get(`/labels/${uid}`);
      return data.isOwned;
    } catch (error) {
      logger.error(error);
      throw new Error('Tag not found');
    }
  }

  async mintTag(uid, title) {
    try {
      await axios.patch(`/labels/${uid}?title=${title}`);
      const { data } = await axios.get(`/labels/${uid}`);
      return data.id;
    } catch (error) {
      logger.error(error);
      throw new Error('Tag not found');
    }
  }

  /* -------------------------------------------------------------------------
   *  LABEL HISTORIES
   * ---------------------------------------------------------------------- */

  async createHistory(labelId) {
    const { data } = await axios.post(`/labels/${labelId}/histories`);
    return data;
  }

  /* -------------------------------------------------------------------------
   *  LABEL HISTORIES ATTACH
   * ---------------------------------------------------------------------- */

  async createAttachHistoryTit(labelId, historyId, contentText) {
    const formData = new FormData();
    formData.append('contentType', 'TIT');
    formData.append('contentText', contentText);
    formData.append('isPublic', 'true');

    const { data } = await axios.post(`/labels/${labelId}/histories/${historyId}/attaches`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    return data;
  }

  async createAttachHistorySign(labelId, historyId) {
    const formData = new FormData();
    const { data } = await axios.post(`/labels/${labelId}/histories/${historyId}/sign`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    return data;
  }

  async createAttachHistoryTxt(labelId, historyId, contentText) {
    const formData = new FormData();
    formData.append('contentType', 'TXT');
    formData.append('contentText', contentText);
    formData.append('isPublic', 'true');

    const { data } = await axios.post(`/labels/${labelId}/histories/${historyId}/attaches`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return data;
  }

  async createAttachHistoryImg(labelId, historyId, attachFile) {
    const formData = new FormData();
    formData.append('contentType', 'IMG');
    formData.append('attachFile', attachFile);
    formData.append('isPublic', 'true');

    const { data } = await axios.post(`/labels/${labelId}/histories/${historyId}/attaches`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    return data;
  }

 /* -------------------------------------------------------------------------
  *  USER
  * ---------------------------------------------------------------------- */

  async getUser() {
    const { data } = await axios.get(`/users`);
    return data;
  }

  async getUserById(id) {
    const { data } = await axios.get(`/users/${id}`);
    return data;
  }

  async updateUser(id, params) {

    // Crear objeto FormData
    let formData = new FormData();

    // Convertir el objeto 'links' en una cadena JSON y añadirlo a formData
    if (params.links && Object.keys(params.links).length > 0) {
      formData.append('links', JSON.stringify(params.links));
    }

    // Verificar y añadir el archivo de imagen si está presente
    if (get(params, 'imageFile')) {
      formData.append('imageFile', params.imageFile);
      delete params.imageFile; // Eliminar de params para no duplicar
    }

    // Añadir los otros campos a formData
    Object.keys(params).forEach(key => {
      if ('links' !== key) {
        formData.append(key, params[key]);
      }
    })

    try {
      // Configuración para solicitud Axios
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      // Realizar la solicitud patch con formData
      const { data } = await axios.patch(`/users`, formData, config);
      return data;
    } catch (error) {
      throw new Error('Error saving your user profile');
    }
  }
}
