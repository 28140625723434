import { UniikuService } from "@/services/uniiku.service";
import createPersistedState from 'vuex-persistedstate';
import { createStore } from 'vuex';

// Firebase
import { auth } from '@/firebase/config';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged
} from 'firebase/auth';
import LogRocket from 'logrocket';
import createPlugin from 'logrocket-vuex';
import { get } from "lodash";

const logrocketPlugin = createPlugin(LogRocket);

// Store
const store = createStore({
  plugins: [
    createPersistedState({
      paths: ['user', 'accessToken']
    }),
    logrocketPlugin
  ],

  /* -------------------------------------------------------------------------
   *  STATES
   * ---------------------------------------------------------------------- */

  state: {
    user: null,
    accessToken: null
  },

  /* -------------------------------------------------------------------------
   *  GETTERS
   * ---------------------------------------------------------------------- */

  getters: {
    user(state) {
      return state.user;
    },
    accessToken(state) {
      return state.accessToken;
    }
  },

  /* -------------------------------------------------------------------------
   *  MUTATIONS
   * ---------------------------------------------------------------------- */

  mutations: {
    setAcessToken(state, payload) {
      state.accessToken = payload ? payload.accessToken : null;
    },
    setAuthIsReady(state, payload) {
      state.authIsReady = payload;
    },
    setUser(state, payload) {
      /*state.accessToken = get(state, 'user.accessToken', null);
      if(payload){
        LogRocket.identify(payload.email);
      }*/
      state.user = payload ? payload : null;
    }
  },

  /* -------------------------------------------------------------------------
   *  ACTIONS
   * ---------------------------------------------------------------------- */

  actions: {
    async singUp(context, { email, password}) {
      const response = await createUserWithEmailAndPassword(auth, email, password);
      if (response) {
        context.commit('setUser', response.user);
      } else {
        throw new Error('could not complete singUp');
      }
    },

    async logIn(context, { email, password}) {
      const response = await signInWithEmailAndPassword(auth, email, password);
      if (response) {
        context.commit('setUser', response.user);
      } else {
        throw new Error('could not complete logIn');
      }
    },

    async resetPassword(context, { email }) {
      await sendPasswordResetEmail(auth, email);
    },

    async signOut(context) {
      await signOut(auth);
      context.commit('setUser', null);
    },

    async fetchAndSetUser(context, payload) {
      if (payload) {
        context.commit('setAcessToken', payload);
        const service = new UniikuService();
        const user = await service.getUser();

        const userData = {
          id: user.id,
          uuid: user.uuid,
          description: user.description,
          imageUrl: user.imageUrl,
          links: user.links,
          location: user.location,
          name: user.name,
          uid: payload.uid,
          accessToken: payload.accessToken,
          displayName: payload.displayName,
          email: payload.email,
          emailVerified: payload.emailVerified,
          isAnonymous: payload.isAnonymous,
          phoneNumber: payload.phoneNumber,
        }

        context.commit('setUser', userData);
      }
      else {
        context.commit('setUser', null);
        context.commit('setAcessToken', null);
      }
    }
  }
})

// Esperar hasta que este listo
onAuthStateChanged(auth, (user) => {
  store.dispatch('fetchAndSetUser', user).then(r => {});
})

export default store;
