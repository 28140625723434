import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

initializeApp({
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_DATABASE_URL,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGE_SENDER_ID,
    appId: process.env.VUE_APP_API_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID
})

const auth = getAuth()
export { auth }
