<template>
  <div v-if="null !== error" class="user-tags tag-error">
    <div class="user-tags-header">
      <div class="wrapper">
        <div class="error-message">
          <strong>Error</strong>
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFormError',
  props: ['error']
}
</script>

<style scoped>
</style>
