<template>
  <main class="site-content form register-form" v-if="visible">
    <div class="wrapper">
      <h1>{{ user.name }}</h1>
      <p><img :src="user.imageUrl" :alt="user.name" class="user-profile-image"></p>
      <h2>{{ user.description }}</h2>
      <p v-show="getInstagram">Instagram: {{ getInstagram }}</p>
      <p v-show="getlinkedin">Linkedin: {{ getlinkedin }}</p>
      <p v-show="getTwitter">Twitter: {{ getTwitter }}</p>
      <p class="form-submit">
        Signature
      </p>
      <p class="form-submit">
        <router-link :to="{ name: 'route_profile', params: { userId: user.uuid }}" class="button orange">{{ user.uuid }}</router-link>
      </p>
      <p class="form-submit">
        uniiku
      </p>
      <p class="form-submit">
        <router-link :to="{ name: 'route_home', params: { tagId: tag.id }}" class="button orange">{{ tag.id }}</router-link>
      </p>
      <p class="form-submit">
        <router-link :to="{ name: 'route_tag_audit', params: { tagId: tag.id }}" class="button">Audit</router-link>
      </p>
    </div>
  </main>
</template>

<script>
import { UniikuService } from '@/services/uniiku.service';
import { get } from 'lodash';

export default {
  name: 'TagSigned',

  /* ------------------------------------------------------------------------------------------------------------ */

  data() {
    return {
      user: null,
      tag: null,
      ustager: null,
      visible: false,
    }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  async mounted() {
    this.service = new UniikuService();

    this.userId = this.$route.params.userId;
    this.tagId = this.$route.params.tagId;

    if (this.userId) {
      this.user = await this.service.getUserById(this.userId);
    }

    if (this.tagId) {
      this.tag = await this.service.getTag(this.tagId);
    }

    if (!this.user || !this.tag) {
      await this.$router.push({ name: 'route_home' });
    }

    this.visible = true;
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  computed: {
    getInstagram() {
      return get(this.user, 'links.instagram', null);
    },
    getlinkedin() {
      return get(this.user, 'links.linkedin', null);
    },
    getTwitter() {
      return get(this.user, 'links.twitter', null);
    }
  }
}
</script>

<style scoped>
  .user-profile-image {
    /* Asegura que la imagen sea un círculo */
    border-radius: 50%;

    /* Ajusta el tamaño de la imagen a tus necesidades */
    width: 100px; /* o el tamaño que prefieras */
    height: 100px; /* debe ser igual al ancho para mantener la proporción de círculo */

    /* Opcional: Añade un borde si lo deseas */
    border: 2px solid #F08F00; /* color de borde negro, ajusta como prefieras */

    /* Opcional: Añade algo de sombra para un efecto elevado */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    /* Asegúrate de que la imagen cubra el contenedor sin deformarse */
    object-fit: cover;
  }
</style>
