import axios from 'axios';
import store from '../store/index'
import { logger } from '../services/logger';

axios.interceptors.request.use(async function (config) {
    try {
        const token = store.getters['accessToken'];
        config.url = `${process.env.VUE_APP_AXIOS_BASE_URL}${config.url}`;
        config.retries = 2;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    } catch (error) {
        logger.error(error);
        return config;
    }
    return config;
})

axios.interceptors.response.use(
    function (response) {
        return response;
    }
)
