<template>
  <footer class="site-footer" id="site-footer">
    <div v-if="!user && 'route_login' !== routeName && 'route_register' !== routeName && 'route_reset_password' !== routeName" class="site-footer-login">
      <div class="wrapper">
        <router-link :to="{ name: 'route_login', params: { tagId: this.$route.params.tagId } }">Log in</router-link>
      </div>
    </div>
    <div class="pre-footer">
      <div class="wrapper">
        <img class="logo" :src="require(`@/assets/img/logo-name.svg`)" alt="Logotipo de uniiku" />
      </div>
    </div>
    <div class="site-footer-about">
      <div class="wrapper">
        <p>Protect the value of special things</p>
        <a class="button" href="https://uniiku.net/">What is this?</a>
      </div>
    </div>
    <div class="site-footer-contact">
      <p>Contact</p>
      <div class="wrapper">
        <ul>
          <li><a :href="urlInstagram" target="_blank"><img :src="require(`@/assets/img/icon-instagram.svg`)" alt="Icono de Instagram"></a></li>
          <li><a :href="urlTwitter" target="_blank"><img :src="require(`@/assets/img/icon-twitter.svg`)" alt="Icono de Twitter"></a></li>
          <li><a :href="urlTelegram" target="_blank"><img :src="require(`@/assets/img/icon-telegram.svg`)" alt="Icono de email"></a></li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  watch: {
    '$store.state.user': function() {
      this.user = this.$store.getters['user'];
    }
  },
  data() {
    return {
      urlInstagram: process.env.VUE_APP_INSTAGRAM,
      urlTelegram: process.env.VUE_APP_TELEGRAM,
      urlTwitter: process.env.VUE_APP_TWITTER,
      user: this.$store.getters['user']
    }
  },
  computed: {
    routeName() {
      return this.$route.name;
    }
  }
};
</script>

<style scoped>
</style>
