<template>
  <span v-if="visible" v-bind:key="itemId">

    <!-- Si esta libre -->
    <div v-if="true === showTagStatus" class="user-tags listing tag-mint">
      <div class="user-tags-header">
        <div class="wrapper" v-on:click="showList">
          <button class="user-tag-content" :class="unfolded">
            <span class="label icon-uniiku">This tag is <strong>blank</strong></span>
            <span class="more-info"></span>
          </button>
        </div>
      </div>
      <div class="user-tags-minted-information" :class="display" v-show="show">
        <div class="wrapper">
          <ul>
            <li><strong>ID:</strong>{{ itemId }}</li>
            <li class="qr"><img :src="tag.physicalImageUrl" alt="Uniiku id image" /></li>
          </ul>
        </div>
      </div>
    </div>

    <!-- No esta libre esta minted por un usaurio -->
    <div v-if="false === showTagStatus" class="user-tags tag-minted">
      <div class="user-tags-header user-tags-listing-items display" style="background-color: #FDEED9;">
        <div class="wrapper">
          <ul>
            <li>
              <a href="#">
                <div class="tag-picture">
                  <img v-if="tag.mainImageUrl" :src="tag.mainImageUrl" alt="Uniiku tag image">
                  <img v-if="null === tag.mainImageUrl" src="/no-image-icon-150.png" alt="Uniiku label image">
                </div>
                {{ tag.title }}
              </a>
            </li>
          </ul>
        </div>
        <div class="wrapper" v-on:click="showList">
          <button class="user-tag-content" :class="unfolded">
            <div>{{ tag.owner.name }}
            <span v-if="tag.set">
              #{{ tag.set.position }}/{{ tag.set.size }}
            </span>
          </div>
            <span class="more-info">
            </span>
          </button>
        </div>
      </div>
      
      <div class="user-tags-minted-information" :class="display" v-show="show">
        <div class="wrapper">
          <ul>
            <li><strong>Signed on:</strong>{{ tag.dateMintedFormat }}</li>
            <li><strong>Signed by {{ tag.owner.name }}:</strong><router-link :to="{ name: 'route_profile', params: { userId: tag.owner.id }}">{{ tag.owner.id }}</router-link></li>
            <li><strong>Uniiku:</strong><router-link :to="{ name: 'route_home', params: { tagId: tag.id }}">{{ tag.id }}</router-link></li>
            <li v-if="tag.set"><strong>Series #{{ tag.set.position }}/{{ tag.set.size }}: </strong> {{ tag.set.id }}</li>
            <li class="qr">
              <img :src="tag.physicalImageUrl" alt="Uniiku id image" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import { UniikuService } from "@/services/uniiku.service";
import { get } from 'lodash';

export default {
  name: 'TagStatus',
  props: {
    tagId: {
      default: null
    }
  },
  watch: {
    async tagId(tagId) {
      this.itemId = tagId;
      await this.loadTag();
    },
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  data() {
    return {
      itemId: null,
      tag: null,
      user: null,
      visible: false,
      unfolded: this.folded,
      display: '',
      showAudit: false,
    }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  async mounted() {
    await this.loadTag();
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  methods: {

    async loadTag() {
      this.visible = false;

      this.service = new UniikuService();
      this.itemId = this.tagId;

      if (this.itemId) {
        this.tag = await this.service.getTagDetail(this.itemId);
        if(this.tag.owner){
          this.user = await this.service.getUserById(this.tag.owner.id);
        }
      }

      this.visible = null !== this.tag;
    },

    showList: function () {
      this.show = true !== this.show;

      if (false === this.show) {
        this.unfolded = '';
        this.display = '';
      } else {
        this.unfolded = 'unfolded';
        this.display = 'display';
      }
    }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  computed: {
    showTagStatus() {
      if (!this.tag) {
        return false;
      }
      const owner = get(this.tag, 'owner', null);
      return !owner;
    }
  }
}
</script>

<style scoped>
.display {
  display: block !important;
}
</style>


