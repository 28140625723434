<template>
  <main class="site-content form register-form" v-if="visible">
    <div class="wrapper">

      <h1>{{ tag.title }}</h1>
      <button class="user-tag-content">
        <span class="label icon-uniiku"></span>
        <span class="more-info">Signed by
            <router-link :to="{ name: 'route_signed', params: { userId: tag.owner.id, tagId: tag.id }}">
              {{ tag.owner.name }}
            </router-link>
          </span>
        <span class="more-info">{{ tag.dateMintedFormat }}</span>
      </button>

      <div class="user-tags-listing-items">
        <router-link :to="{ name: 'route_home', params: { tagId: tag.id }}">
          <div class="tag-picture">
            <img v-if="tag.image" :src="tag.image" alt="Uniiku label image">
            <img v-if="null === tag.image" src="/no-image-icon-150.png" alt="Uniiku label image">
          </div>
        </router-link>
      </div>

      <p class="form-submit">
        Signature
      </p>
      <p class="form-submit">
        <router-link :to="{ name: 'route_profile', params: { userId: user.uuid }}" class="button orange">{{ user.uuid }}</router-link>
      </p>
      <p class="form-submit">
        uniiku
      </p>
      <p class="form-submit">
        <router-link :to="{ name: 'route_home', params: { tagId: tag.id }}" class="button orange">{{ tag.id }}</router-link>
      </p>
      <div class="user-tags-minted-information">
        <div class="wrapper">
          <ul>
            <li>
              <strong>ID:</strong>
              {{ tag.id }}
            </li>
            <li>
              <strong>Minted on:</strong>
              {{ tag.dateMintedFormat }}
            </li>
            <li class="qr">
              <router-link :to="{ name: 'route_home', params: { tagId: tag.id }}">
                <img :src="tag.imageUrl" alt="Uniiku image id" />
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { UniikuService } from '@/services/uniiku.service';

export default {
  name: 'TagAudit',

  /* ------------------------------------------------------------------------------------------------------------ */

  data() {
    return {
      user: null,
      userId: null,
      tag: null,
      visible: false,
    }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  async mounted() {
    this.service = new UniikuService();

    this.userId = this.$route.params.userId;
    this.tagId = this.$route.params.tagId;

    if (this.userId) {
      this.user = await this.service.getUserById(this.userId);
    }

    if (this.tagId) {
      this.tag = await this.service.getTagDetail(this.tagId);
    }

    if (!this.user || !this.tag) {
      await this.$router.push({ name: 'route_home' });
    }

    this.visible = true;
  }
}
</script>

<style scoped>
  .user-profile-image {
    /* Asegura que la imagen sea un círculo */
    border-radius: 50%;

    /* Ajusta el tamaño de la imagen a tus necesidades */
    width: 100px; /* o el tamaño que prefieras */
    height: 100px; /* debe ser igual al ancho para mantener la proporción de círculo */

    /* Opcional: Añade un borde si lo deseas */
    border: 2px solid #F08F00; /* color de borde negro, ajusta como prefieras */

    /* Opcional: Añade algo de sombra para un efecto elevado */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    /* Asegúrate de que la imagen cubra el contenedor sin deformarse */
    object-fit: cover;
  }
</style>
