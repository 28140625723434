/* eslint-disable no-console */
class Logger {
  async info(message) {
    console.log(message);
  }
  async error(message) {
    console.error(message);
  }
}
/* eslint-enable no-console */
export const logger = new Logger();