<template>
  <div v-if="userProfile" class="user-tags listing" >
    <div class="wrapper">
      <h1>{{ userProfile.name }}</h1>
      <p><img :src="userProfile.imageUrl" :alt="userProfile.name" class="user-profile-image"></p>
      <p class="form-submit" v-if="isSameUser">
          <button @click="showForm()" type="submit" class="button" value="My profile">Edit my profile</button>
      </p>
      <h2>{{ userProfile.location }}</h2>
      <p v-show="getInstagram"><a :href="getInstagram" target="_blank">Instagram</a></p>
      <p v-show="getlinkedin"><a :href="getlinkedin" target="_blank">Linkedin</a></p>
      <p v-show="getTwitter"><a :href="getTwitter" target="_blank">Twitter</a></p>
      <p>{{ userProfile.description }}</p>
    </div>
    

    <!-- Profile user tags -->
    <div class="user-tags-listing-items" v-if="tagsProfile" style="display: inline">
      <div class="wrapper">
        <h2>Signed by {{ userProfile.name }}</h2>
        <ul>
          <li v-for="tag in tagsProfile" v-bind:key="tag.id">
            <router-link :to="{ name: 'route_home', params: { tagId: tag.id }}">
              <div class="tag-picture">
                <img v-if="tag.mainImageUrl" :src="tag.mainImageUrl" alt="Uniiku label image">
                <img v-if="null === tag.mainImageUrl" src="/no-image-icon-150.png" alt="Uniiku label image">
              </div>
              <div class="tag-description">
                <strong>{{ tag.title }}</strong>
                <p>{{ tag.subtitle }}</p>
              </div>
            </router-link>
          </li>
        </ul>
        <p class="form-submit" v-if="isSameUser">
          <button @click="signOut()" type="submit" class="button" value="My profile">Sign out</button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { UniikuService } from '@/services/uniiku.service';
import { get } from 'lodash';

export default {
  name: 'Profile',
  watch: {
    '$store.state.user': function() {
      this.user = this.$store.getters['user'];
    }
  },

  methods: {
    async signOut() {
      await this.$store.dispatch('signOut');
      await this.$router.push({ name: 'route_login' });
    },
    async showForm() {
      await this.$router.push({ name: 'route_edit_profile' });
    }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  data() {
    return {
      user: this.$store.getters['user'], // Ususrio logado

      userId: this.$route.params.userId, // Usuuario del perfil
      tagsProfile: null,
      userProfile: null,
    }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  async mounted() {
    this.service = new UniikuService();

    if(this.userId){
      this.userProfile = await this.service.getUserById(this.userId);
      this.tagsProfile = await this.service.getUserTags(this.userId);
    }else{
      this.userProfile = await this.service.getUser();
      this.tagsProfile = await this.service.getTags();
    }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  computed: {
    getInstagram() {
      return get(this.userProfile, 'links.instagram', null);
    },

    getlinkedin() {
      return get(this.userProfile, 'links.linkedin', null);
    },

    getTwitter() {
      return get(this.userProfile, 'links.twitter', null);
    },
    isSameUser() {
      return this.user && this.user.uuid === this.userProfile.uuid;
    },
  }
}
</script>

<style scoped>
.user-profile-image {
  /* Asegura que la imagen sea un círculo */
  border-radius: 50%;

  /* Ajusta el tamaño de la imagen a tus necesidades */
  width: 100px; /* o el tamaño que prefieras */
  height: 100px; /* debe ser igual al ancho para mantener la proporción de círculo */

  /* Opcional: Añade un borde si lo deseas */
  border: 2px solid #F08F00; /* color de borde negro, ajusta como prefieras */

  /* Opcional: Añade algo de sombra para un efecto elevado */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  /* Asegúrate de que la imagen cubra el contenedor sin deformarse */
  object-fit: cover;
}
</style>
