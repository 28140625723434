<template>
  <header class="site-header">
    <div class="wrapper">
      <div v-if="user" class="site-header-content">
        <router-link class="logo" :to="{ name: 'route_home' }" @click="updateTag(null)">
          <img :src="require(`@/assets/img/logo.svg`)" alt="Logo de Uniiku" />
        </router-link>
        <button v-if="user.imageUrl" @click="myProfile" class="site-header-content">
          <strong>{{ user.name }}</strong>&nbsp;
          <img class="user-profile-image" :src="user.imageUrl" :alt="user.name" :title="user.name" />
        </button>
        <button v-else @click="myProfile" class="site-header-content">
          <img class="icon-user" :src="require(`@/assets/img/icon-user.svg`)" alt="Usuario logueado" />
        </button>
      </div>
      <div v-if="!user" class="site-header-content">
        <router-link class="logo" :to="{ name: 'route_home' }" @click="updateTag(null)">
          <img :src="require(`@/assets/img/logo.svg`)" alt="Logo de Uniiku" />
        </router-link>
        <button @click="logIn" class="site-header-content">
          <strong>Log In</strong>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  watch: {
    '$store.state.user': function() {
      this.user = this.$store.getters['user'];
    }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  data() {
    return {
      user: this.$store.getters['user']
    }
  },

  /* ------------------------------------------------------------------------------------------------------------ */

  methods: {
    async logIn() {
      await this.$router.push({ name: 'route_login' });
    },

    async myProfile() {
      await this.$router.push({ name: 'route_profile', params:{userId: this.user.uuid}});
    },

    updateTag(uid) {
      this.$emit('update-detail', uid);
    }
  }
};
</script>

<style scoped>
.logo-nolink {
  cursor: default;
}
.user-profile-image {
  /* Asegura que la imagen sea un círculo */
  border-radius: 50%;

  /* Ajusta el tamaño de la imagen a tus necesidades */
  width: 40px; /* o el tamaño que prefieras */
  height: 40px; /* debe ser igual al ancho para mantener la proporción de círculo */

  /* Opcional: Añade un borde si lo deseas */
  border: 1px solid #FDEED9; /* color de borde negro, ajusta como prefieras */

  /* Opcional: Añade algo de sombra para un efecto elevado */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  /* Asegúrate de que la imagen cubra el contenedor sin deformarse */
  object-fit: cover;
}
</style>
