<template>
  <span>
    <AppHeader/>
    <div class="viewport-default">
      <router-view/>
    </div>
    <AppFooter/>
  </span>
</template>

<script>
import AppHeader from "@/components/app/AppHeader.vue";
import AppFooter from "@/components/app/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter
  }
}
</script>

<style>
</style>
