import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/components/Home.vue';
import NotFound from '@/components/NotFound';
import ResetPassword from "@/components/user/ResetPassword.vue";
import EditProfile from "@/components/user/EditProfile.vue";
import Register from '@/components/user/Register.vue';
import Profile from "@/components/user/Profile.vue";
import Login from '@/components/user/Login.vue';

import TagSigned from "@/components/tag/TagSigned.vue";
import TagAudit from "@/components/tag/TagAudit.vue";

import store from '../store/index';

const routes = [
  {
    path: "/:tagId?",
    name: "route_home",
    component: Home,
  },
  {
    path: "/login/:tagId?",
    name: "route_login",
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/register/:tagId?",
    name: "route_register",
    component: Register,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/reset-password/:tagId?",
    name: "route_reset_password",
    component: ResetPassword,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/logout/:tagId?",
    name: "route_logout",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/edit-profile/",
    name: "route_edit_profile",
    component: EditProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/audit/:userId/:tagId",
    name: "route_tag_audit",
    component: TagAudit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/profile/:userId?",
    name: "route_profile",
    component: Profile
  },
  {
    path: "/signed/:userId/:tagId",
    name: "route_signed",
    component: TagSigned
  },{
    path: "/404",
    name: "not-found",
    component: NotFound
  }
];

const router = createRouter({
  base: process.env.BASE_URL,
  history: createWebHistory(process.env.BASE_URL),
  routes: routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = store.state.user;

  if ('route_logout' === to.name && isAuthenticated) {
    store.dispatch('signOut');
    next({ name: 'route_home', params: { tagId: to.params.uid }});
  }

  else if ('route_register' === to.name && isAuthenticated) {
    next({ name: 'route_home', params: { tagId: to.params.uid }});
  }

  else if ('route_reset_password' === to.name && isAuthenticated) {
    next({ name: 'route_home', params: { tagId: to.params.uid }});
  }

  else if ('route_login' === to.name && isAuthenticated) {
    next({ name: 'route_home', params: { tagId: to.params.uid }});
  }
  else {
    next();
  }
})

export default router;
